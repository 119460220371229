import { Select, notification, Space } from 'antd';
import { useState, useEffect } from 'react';
import {
  useGetAdPreparationQuery,
  useUpdatePreparationStateMutation,
} from '@gql_codegen/retail-types';
import useAdId from '@hooks/useAdId';
import { retailKibanaLogger } from '@utils/logger';
import { getServerErrors } from '@utils/getServerErrors';

export const AdPreparationState = (): JSX.Element => {
  const [status, setStatus] = useState<string>('');
  const adId = useAdId() ?? '';
  const { data, isLoading } = useGetAdPreparationQuery({ adId });

  const { mutateAsync, isLoading: isMutating } =
    useUpdatePreparationStateMutation();
  const { preparationStateOptions = [] } = data?.adPreparation ?? {};

  useEffect(() => {
    if (data) {
      setStatus(data.adPreparation.preparationState);
    }
  }, [data]);

  const handleStatusChange = async (val: string) => {
    try {
      const mutataionResponse = await mutateAsync({
        adId,
        preparationState: val,
      });
      setStatus(mutataionResponse.updatePreparationState.preparationState);

      notification.success({
        message: 'Preparation Status has been changed successfully!',
        style: {
          width: '100%',
        },
      });
    } catch (error: unknown) {
      retailKibanaLogger.warn(
        'AdPreparationState::handleStatusChange -> Preparation Status change has failed',
        error,
      );
      notification.error({
        message: 'Error occured during Preparation Status update:',
        description: (
          <Space direction="vertical">{getServerErrors(error)}</Space>
        ),
        style: {
          width: '100%',
        },
      });
    }
  };

  return (
    <>
      <label>Preparation State:</label>
      <Select
        data-qa-selector="preparation-state-select"
        value={status}
        options={preparationStateOptions}
        loading={isLoading || isMutating}
        disabled={isLoading || isMutating}
        onChange={handleStatusChange}
        size={'large'}
        style={{ width: '120px', marginLeft: '10px' }}
      ></Select>
    </>
  );
};

import { Subsection } from '@components/Subsection';
import DatePickerControlled from '@components/formControlled/DatePickerControlled';
import InputControlled from '@components/formControlled/InputControlled';
import InputNumberControlled from '@components/formControlled/InputNumberControlled';
import SelectControlled from '@components/formControlled/SelectControlled';
import SwitchControlled from '@components/formControlled/SwitcherControlled';
import { OptionNumberItem, OptionStringItem } from '@gql_codegen/retail-types';
import { useAppForm } from '@hooks/useAppForm';
import { usePageData } from '@hooks/usePageData';
import { SubTypeControlled } from '@sections/General/CarData/subTypeField';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { getCurrencySymbolByCode } from '@utils/currency';
import { CUSTOM_DATE_FORMATS } from '@utils/date';
import { cleanYearInput, restrictFloatInput } from '@utils/validators';
import { Col, Flex, Form, Row, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { find } from 'lodash/fp';
import { useWatch } from 'react-hook-form';
import { prepareOptionList } from './prepareOptionList';
import { TrimLevelHint } from './trimLevelHint';

import CheckboxControlled from '@components/formControlled/CheckboxControlled';
import GrossPriceFormItem from '@sections/General/CarData/GrossPrice';
import { filterOptionsHandler } from '@sections/General/CarData/utils/filterOptionsHandler';
import { Model } from './model';
import cn from './styles.less';

const { Text } = Typography;

export const CarData = (): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useAppForm();
  const generalErrors = errors?.adMgmt?.general?.data || {};
  const { data } = usePageData();
  const { dictionaries, currencyCode, general } = data?.adMgmt || {};
  const {
    bodyTypeOptions,
    gearTypeOptions,
    primaryWheelSeasonOptions,
    taxRatingOptions,
    carTypeOptions,
    colorOptions,
    upholsteryOptions,
    driveTrain: driveTrainOptions,
    upholsteryColor: upholsteryColorOptions,
    trimLevels,
  } = dictionaries?.global || {};
  const { restrictions, data: generalData } = general || {};
  const {
    canSeeCarType,
    canSeeManufacturerCode,
    canSeeModelCode,
    canSeeBpm,
    canSeeFinancedPrice,
    canEditVehicleFields,
    canEditGrossPrice,
  } = restrictions || {};
  const { partner, color, upholstery } = generalData || {};
  const [registration, horsePower] = useWatch({
    control,
    name: [
      'adMgmt.general.data.registration',
      'adMgmt.general.data.horsePower',
    ],
  });

  const prepareColorsOptionList = (
    options: OptionNumberItem[] | OptionStringItem[] | undefined,
    field: keyof OptionStringItem = 'label',
  ) => {
    if (!options) {
      return [];
    }
    const result = options.map(
      (option: OptionNumberItem | OptionStringItem, index: number) => {
        return (
          <Select.Option key={index} value={option.value} label={option.label}>
            <span style={{ color: option.label }}>&#9673;</span>
            &nbsp;
            {option[field]}
          </Select.Option>
        );
      },
    );
    if (color && find({ value: color })(colorOptions)) {
      return result;
    } else {
      return [
        ...result,
        <Select.Option key="other" value={color} label="Other">
          Other
        </Select.Option>,
      ];
    }
  };

  return (
    <Subsection title="Car Data">
      <Row gutter={24} style={{ marginBottom: 20 }}>
        <Col>
          Available at Showroom &nbsp;
          <SwitchControlled
            disabled={true}
            checkedChildren="Yes"
            unCheckedChildren="No"
            data-qa-selector="general-showroom-availability-switcher"
            controllerProps={{
              control,
              name: `adMgmt.general.data.showroomAvailability.available`,
            }}
            tracking={{
              eventCategory: 'modify',
              section: TRACKING_SECTION.CAR_DATA,
              sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              fieldId: 'Available at Showroom',
            }}
          />
        </Col>
        <Col>
          Local Retail &nbsp;
          <SwitchControlled
            disabled={true}
            checkedChildren="Yes"
            unCheckedChildren="No"
            data-qa-selector="general-local-showroom-availability-switcher"
            controllerProps={{
              control,
              name: `adMgmt.general.data.localRetail`,
            }}
            tracking={{
              eventCategory: 'modify',
              section: TRACKING_SECTION.CAR_DATA,
              sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              fieldId: 'Local Retail',
            }}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Model />
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-manufacturer"
            label="Manufacturer"
          >
            <InputControlled
              data-qa-selector="general-manufacturer-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.manufacturer',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Manufacturer',
              }}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item data-qa-selector="general-status" label="Status">
            <InputControlled
              data-qa-selector="general-status-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.status',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Status',
              }}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-trim-level"
            label={<TrimLevelHint />}
          >
            <SelectControlled
              data-qa-selector="general-trim-level-selector"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.trimLevel',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Trim level',
              }}
            >
              {prepareOptionList(trimLevels)}
            </SelectControlled>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-stock-number"
            label="Stock Number"
          >
            <InputControlled
              data-qa-selector="general-stock-number-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.stockNumber',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Stock Number',
              }}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item data-qa-selector="general-vin" label="VIN">
            <InputControlled
              data-qa-selector="general-vin-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.vin',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'VIN',
              }}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <SubTypeControlled filterOptionsHandler={filterOptionsHandler} />
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-subtype-extra"
            label="Subtype Extra"
            validateStatus={generalErrors?.subTypeExtra ? 'error' : 'success'}
            help={
              <div data-qa-selector="general-subtype-extra-explain-error">
                {generalErrors?.subTypeExtra?.message}
              </div>
            }
          >
            <InputControlled
              data-qa-selector="general-subtype-extra-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.subTypeExtra',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Subtype Extra',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <GrossPriceFormItem
          canEditGrossPrice={!!canEditGrossPrice}
          currencyCode={currencyCode}
        />
        <Col span={6}>
          <Form.Item data-qa-selector="general-net-price" label="Net Price">
            <InputControlled
              data-qa-selector="general-net-price-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.netPriceMajorUnits',
              }}
              addonBefore={getCurrencySymbolByCode(currencyCode)}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Net Price',
              }}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item data-qa-selector="general-vat" label="VAT">
            <InputControlled
              data-qa-selector="general-vat-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.vatAmount',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'VAT',
              }}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item data-qa-selector="general-vat-type" label="VAT type">
            <InputControlled
              data-qa-selector="general-vat-type-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.vatType',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'VAT type',
              }}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-built-year"
            label="Built Year"
            validateStatus={generalErrors?.builtYear ? 'error' : 'success'}
            help={
              <div data-qa-selector="general-built-year-explain-error">
                {generalErrors?.builtYear?.message}
              </div>
            }
          >
            <InputNumberControlled
              data-qa-selector="general-built-year-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.builtYear',
              }}
              min="1900"
              max={new Date().getFullYear()}
              onKeyDown={cleanYearInput}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Built Year',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-body-type"
            label="Body Type"
            validateStatus={generalErrors?.bodyType ? 'error' : 'success'}
            help={
              <div data-qa-selector="general-body-type-explain-error">
                {generalErrors?.bodyType?.message}
              </div>
            }
          >
            <SelectControlled
              data-qa-selector="general-body-type-select"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.bodyType',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Body Type',
              }}
            >
              {prepareOptionList(bodyTypeOptions)}
            </SelectControlled>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-gear-type"
            label="Gear Type"
            required
            validateStatus={generalErrors?.gearType ? 'error' : 'success'}
            help={
              <div data-qa-selector="general-gear-type-explain-error">
                {generalErrors?.gearType?.message}
              </div>
            }
          >
            <SelectControlled
              data-qa-selector="general-gear-type-select"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.gearType',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Gear Type',
              }}
            >
              {prepareOptionList(gearTypeOptions)}
            </SelectControlled>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-wheel-season"
            label="Wheel Season"
            validateStatus={
              generalErrors?.primaryWheelSeason ? 'error' : 'success'
            }
            help={
              <div data-qa-selector="general-wheel-season-explain-error">
                {generalErrors?.primaryWheelSeason?.message}
              </div>
            }
          >
            <SelectControlled
              data-qa-selector="general-wheel-season-select"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.primaryWheelSeason',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Wheel Season',
              }}
            >
              {prepareOptionList(primaryWheelSeasonOptions)}
            </SelectControlled>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            label="Door count"
            data-qa-selector="general-door-count"
            help={
              <div data-qa-selector="general-door-count-explain-error">
                {generalErrors?.seatCount?.message}
              </div>
            }
            validateStatus={generalErrors?.seatCount ? 'error' : 'success'}
          >
            <InputNumberControlled
              min={1}
              max={9}
              step={1}
              precision={0}
              controllerProps={{
                control,
                name: 'adMgmt.general.data.doorCount',
              }}
              data-qa-selector="general-door-count-input"
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Door count',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            htmlFor="general-driveTrain-input"
            label={
              <span data-qa-selector="general-driveTrain">Drivetrain</span>
            }
          >
            <SelectControlled
              showSearch
              id="general-driveTrain-input"
              filterOption={filterOptionsHandler}
              data-qa-selector="general-driveTrain-select"
              placeholder="Select..."
              controllerProps={{
                control,
                name: 'adMgmt.general.data.driveTrain',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Drivetrain',
              }}
            >
              {prepareOptionList(driveTrainOptions)}
            </SelectControlled>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Number of Seats"
            data-qa-selector="general-number-of-seats"
            help={
              <div data-qa-selector="general-number-of-seats-explain-error">
                {generalErrors?.seatCount?.message}
              </div>
            }
            validateStatus={generalErrors?.seatCount ? 'error' : 'success'}
          >
            <InputNumberControlled
              min={0}
              max={99}
              step={1}
              precision={0}
              controllerProps={{
                control,
                name: 'adMgmt.general.data.seatCount',
              }}
              data-qa-selector="general-number-of-seats-input"
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Number of Seats',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-number-of-keys"
            label="Number of Keys"
            required
            validateStatus={
              generalErrors?.handoverKeyCount ? 'error' : 'success'
            }
            help={
              <div data-qa-selector="general-number-of-keys-explain-error">
                {generalErrors?.handoverKeyCount?.message}
              </div>
            }
          >
            <InputNumberControlled
              data-qa-selector="general-number-of-keys-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.handoverKeyCount',
              }}
              min={1}
              max={99}
              onKeyDown={restrictFloatInput}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Number of Keys',
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-registration-date"
            label="Registration date"
          >
            <DatePickerControlled
              placeholder="DD/MM/YYYY"
              format={['DD/MM/YYYY', 'DD.MM.YYYY']}
              data-qa-selector="general-registration-date-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.registration',
              }}
              disabledDate={(value) => value.isAfter(dayjs())}
              disabled={!canEditVehicleFields}
              style={{ marginBottom: 5, display: 'block' }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Registration date',
              }}
            />
            {registration === null && general?.data?.registration ? (
              <Text
                type="warning"
                data-qa-selector="general-registration-date-description"
              >
                No empty value allowed: &quot;
                {dayjs(
                  general?.data?.registration,
                  CUSTOM_DATE_FORMATS.BACKEND_FORMAT,
                ).format('DD/MM/YYYY')}
                &quot; will be restored
              </Text>
            ) : null}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item data-qa-selector="general-hp" label="HP">
            <InputNumberControlled
              step={1}
              min={0}
              max={9999}
              precision={0}
              data-qa-selector="general-hp-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.horsePower',
              }}
              disabled={!canEditVehicleFields}
              style={{ marginBottom: 5 }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'HP',
              }}
            />
            {horsePower === null && general?.data?.horsePower ? (
              <Text type="warning" data-qa-selector="general-hp-description">
                No empty value allowed: &quot;{general?.data?.horsePower}&quot;
                will be restored
              </Text>
            ) : null}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        {canSeeCarType && (
          <Col span={6}>
            <Form.Item
              data-qa-selector="general-car-type"
              label="Car Type"
              required
              validateStatus={generalErrors?.carType ? 'error' : 'success'}
              help={
                <div data-qa-selector="general-car-type-explain-error">
                  {generalErrors?.carType?.message}
                </div>
              }
            >
              <SelectControlled
                data-qa-selector="general-car-type-select"
                controllerProps={{
                  control,
                  name: 'adMgmt.general.data.carType',
                }}
              >
                {prepareOptionList(carTypeOptions)}
              </SelectControlled>
            </Form.Item>
          </Col>
        )}

        {canSeeCarType && (
          <Col span={6}>
            <Form.Item
              data-qa-selector="general-tax-rating"
              label="Tax Rating"
              required
              validateStatus={generalErrors?.taxRating ? 'error' : 'success'}
              help={
                <div data-qa-selector="general-tax-rating-explain-error">
                  {generalErrors?.taxRating?.message}
                </div>
              }
            >
              <SelectControlled
                data-qa-selector="general-tax-rating-select"
                controllerProps={{
                  control,
                  name: 'adMgmt.general.data.taxRating',
                }}
              >
                {prepareOptionList(taxRatingOptions)}
              </SelectControlled>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={24}>
        {canSeeManufacturerCode && (
          <Col span={6}>
            <Form.Item
              data-qa-selector="general-hsn"
              label="HSN"
              validateStatus={
                generalErrors?.manufacturerCode ? 'error' : 'success'
              }
              help={
                <div data-qa-selector="general-hsn-explain-error">
                  {generalErrors?.manufacturerCode?.message}
                </div>
              }
            >
              <InputControlled
                data-qa-selector="general-hsn-input"
                controllerProps={{
                  control,
                  name: 'adMgmt.general.data.manufacturerCode',
                }}
                tracking={{
                  eventCategory: 'modify',
                  section: TRACKING_SECTION.CAR_DATA,
                  sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                  fieldId: 'HSN',
                }}
              />
            </Form.Item>
          </Col>
        )}

        {canSeeModelCode && (
          <Col span={6}>
            <Form.Item data-qa-selector="general-tsn-vsn" label="TSN/VSN">
              <InputControlled
                data-qa-selector="general-tsn-vsn-input"
                controllerProps={{
                  control,
                  name: 'adMgmt.general.data.modelCode',
                }}
                tracking={{
                  eventCategory: 'modify',
                  section: TRACKING_SECTION.CAR_DATA,
                  sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                  fieldId: 'TSN/VSN',
                }}
                disabled
              />
            </Form.Item>
          </Col>
        )}

        {canSeeBpm && (
          <Col span={6}>
            <Form.Item
              data-qa-selector="general-bpm"
              label="BPM"
              validateStatus={
                generalErrors?.bpmMajorUnits ? 'error' : 'success'
              }
              help={
                <div data-qa-selector="general-bpm-explain-error">
                  {generalErrors?.bpmMajorUnits?.message}
                </div>
              }
            >
              <InputNumberControlled
                data-qa-selector="general-bpm-input"
                controllerProps={{
                  control,
                  name: 'adMgmt.general.data.bpmMajorUnits',
                }}
                addonBefore={getCurrencySymbolByCode(currencyCode)}
                onKeyDown={restrictFloatInput}
                min={0}
                step={1}
              />
            </Form.Item>
          </Col>
        )}

        {canSeeFinancedPrice && (
          <Col span={6}>
            <Form.Item
              data-qa-selector="general-financed-price"
              label="Financed Price"
              validateStatus={
                generalErrors?.financedPriceMajorUnits ? 'error' : 'success'
              }
              help={
                <div data-qa-selector="general-financed-price-explain-error">
                  {generalErrors?.financedPriceMajorUnits?.message?.toString()}
                </div>
              }
            >
              <InputNumberControlled
                data-qa-selector="general-financed-price-input"
                controllerProps={{
                  control,
                  name: 'adMgmt.general.data.financedPriceMajorUnits',
                }}
                addonBefore={getCurrencySymbolByCode(currencyCode)}
                disabled
              />
            </Form.Item>
          </Col>
        )}
        {partner && (
          <Col span={6}>
            <Form.Item data-qa-selector="general-partner" label="Partner">
              <InputControlled
                data-qa-selector="general-partner-input"
                controllerProps={{
                  control,
                  name: 'adMgmt.general.data.partner.name',
                }}
                disabled
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={24} align="bottom">
        <Col span={6}>
          <Form.Item
            htmlFor="general-exterior-color-input"
            label={
              <span data-qa-selector="general-exterior-color">
                Exterior color
              </span>
            }
          >
            <SelectControlled
              showSearch
              id="general-exterior-color-input"
              filterOption={filterOptionsHandler}
              data-qa-selector="general-exterior-color-select"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.color',
              }}
              placeholder="Select..."
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Exterior color',
              }}
            >
              {prepareColorsOptionList(colorOptions)}
            </SelectControlled>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            htmlFor="general-oemPaintName-input"
            label={
              <span data-qa-selector="general-oemPaintName">
                OEM Paint Name
              </span>
            }
          >
            <InputControlled
              data-qa-selector="general-oemPaintName-input"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.oemPaintName',
              }}
              maxLength={30}
              tracking={{
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                eventCategory: 'modify',
                fieldId: 'OEM Paint Name',
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item htmlFor="general-metallic-input">
            <CheckboxControlled
              data-qa-selector="general-metallic-checkbox"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.metallic',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Metallic',
              }}
            >
              Metallic
            </CheckboxControlled>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            className={cn.label}
            htmlFor="general-upholstery-color-input"
            label={
              <span data-qa-selector="general-upholstery-color">
                Upholstery Color
              </span>
            }
          >
            <SelectControlled
              showSearch
              id="general-upholstery-color-input"
              filterOption={filterOptionsHandler}
              data-qa-selector="general-upholstery-color-select"
              controllerProps={{
                control,
                name: 'adMgmt.general.data.upholsteryColor',
              }}
              placeholder="Select..."
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Upholstery color',
              }}
            >
              {prepareColorsOptionList(upholsteryColorOptions)}
            </SelectControlled>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            className={cn.label}
            htmlFor="general-upholstery-input"
            label={
              <Flex justify="space-between" flex="auto">
                <span data-qa-selector="general-upholstery">Upholstery</span>
                {upholstery && (
                  <SwitchControlled
                    checkedChildren="Original"
                    unCheckedChildren="Original"
                    data-qa-selector="general-original-upholstery-switcher"
                    controllerProps={{
                      control,
                      name: `adMgmt.general.data.upholsteryOriginal`,
                    }}
                    tracking={{
                      eventCategory: 'modify',
                      section: TRACKING_SECTION.CAR_DATA,
                      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                      fieldId: 'Upholstery Original',
                    }}
                  />
                )}
              </Flex>
            }
          >
            <SelectControlled
              showSearch
              id="general-upholstery-input"
              filterOption={filterOptionsHandler}
              data-qa-selector="general-upholstery-select"
              placeholder="Select..."
              controllerProps={{
                control,
                name: 'adMgmt.general.data.upholstery',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.CAR_DATA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Upholstery',
              }}
            >
              {prepareOptionList(upholsteryOptions)}
            </SelectControlled>
          </Form.Item>
        </Col>
      </Row>
    </Subsection>
  );
};
